<template>
  <div class="about">
    <div class="site-section bg-light">
      <div class="container">
        <div class="row mb-5">
          <div class="col-12 text-center" style="margin-top: 60px;">
            <h2 class="section-title mb-3 text-normal-blue">About Us</h2>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-lg-5" data-aos="fade-right">
            <img src="images/undraw_team_ih79.svg" alt="Image" class="img-fluid">
          </div>
          <div class="col-lg-7 ml-auto pl-lg-7">
            <h2 class="text-normal-blue mb-4 h4 font-weight-bold">Together, we enable process to multiply revenues and bring change and experience that matters to customer and employees</h2>

            <p class="mb-4 text-black">Clikry.com was founded by senior partners and comprised of consultants who previously held leadership positions at firms such as HPE, IBM, Price Waterhouse, Bank of America, E&Y, Microsoft, Amazon, Cognizant, and other Fortune 500 and Large Consulting Group.</p>
            <p class="mb-4 text-black">Our partners have led, advised, and/or managed data analytics, operational systems  for  pre and post-merger integrations and change initiative projects that have cut cost and generated over $2 billion in revenues for market-leading clients.</p>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-lg-7 ml-auto pl-lg-7">
            <h2 class="text-normal-blue mb-4 h4 font-weight-bold">Our clients' success is our success</h2>
 
            <p class="mb-4 text-black">Clikry Consultancy is characterized by a deep commitment to creating exceptional enterprise value for its clients. We achieve this by hiring the best people, solving problems rapidly, implementing our solutions, and always driving toward results.</p>
 
             <p class="mb-4 text-black">We are successful on our projects because we share following common traits with our clients. </p>
            <ul class="ul-check mb-5 list-unstyled success text-black">
              <li>Transparency, Honesty and Integrity</li>
              <li>Collaboration and Communication</li>
              <li>Results driven partnership</li>
              <li>Focused and accountable to deliverables</li>
              <li>Agents for change </li>
              <li>Manage by data and facts</li>
              <li>Leverage Technology be committed to success</li>
              <li>Committed to innovative ideas and timeline</li>
              <li>Increase the X factor by making incremental changes</li>
              <li>Dissatisfied with the status quo</li>
            </ul>
          </div>
          <div class="col-lg-5" data-aos="fade-right">
            <br><br><br><br><br>
            <img src="images/undraw_agreement_aajr.svg" alt="Image" class="img-fluid">
          </div>   
        </div>
      </div>
    </div>

    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    Footer
  }
};
</script>